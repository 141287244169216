<template>
  <div class="link-container" :class="[variant]" @click="$emit('click')">
    <MIcon v-if="iconLeft" :pack="iconPack" class="left" :icon="iconLeft" />
    <component :is="isType" class="link" v-bind="attributes">
      <slot />
    </component>
    <MIcon v-if="iconRight && !iconLeft" :pack="iconPack" class="icon right" :icon="iconRight" />
  </div>
</template>

<script>
import MIcon from './Icon.vue';
import { computed } from 'vue';

export default {
  name: 'MLink',
  status: 'ready',
  release: '1.0.0',
  components: { MIcon },
  props: {
    isType: {
      type: String,
      default: 'a',
      validator: (val) => {
        return ['a', 'router-link'].indexOf(val) > -1;
      }
    },
    /**
     * Link destination (href). You can also use the `@click` event.
     */
    to: {
      type: [Boolean, String, Object],
      default: false
    },
    /**
     * Icon left for the link, default hidden
     */
    iconLeft: {
      default: false,
      type: [Boolean, String]
    },
    /**
     * Icon right for the link
     */
    iconRight: {
      default: 'chevron-right',
      type: [Boolean, String]
    },
    /**
     * Default icon pack used for the link
     */
    iconPack: {
      default: 'fas',
      type: String
    },
    /**
     * Target of the link, `_blank` or `_self`
     */
    target: {
      default: '_blank',
      type: String,
      validator: (val) => {
        return ['_blank', '_self', ''].indexOf(val) > -1;
      }
    },
    /**
     * Link color variant. Can be `is-primary`, `is-text`, `is-dark`, `is-darker`
     */
    variant: {
      type: [Boolean, String],
      default: 'is-primary'
    }
  },
  emits: ['click'],
  setup (props) {
    const attributes = computed(() => {
      if (props.isType === 'router-link') {
        return {
          to: props.to,
          target: props.target
        };
      }

      return {
        href: props.to,
        target: props.target
      };
    });

    return {
      attributes
    };
  }
};
</script>

<style scoped lang="scss">
@use "sass:color";
@use '/submodules/vue-framework/src/scss/Colors.scss' as *;

.link-container {
  display: inline-flex;
  align-items: center;
  flex-direction: row;

  a {
    display: flex;
    flex-direction: row;
    font-family: Roboto, sans-serif;
  }

  a,
  .icon {
    text-decoration: none;
  }

  &:hover {
    font-weight: bold;
  }

  .icon {
    font-size: 14px;
  }

  .icon.left {
    margin-right: 5px;
  }

  .icon.right {
    margin-left: 5px;
  }

  cursor: pointer;

  &.is-primary {
    a,
    .icon {
      color: $primary;

      &:hover {
        color: color.adjust($primary, $lightness: -10%);
      }
    }
  }

  &.is-text {
    a,
    .icon {
      color: $text;

      &:hover {
        color: color.adjust($text, $lightness: -10%);
      }
    }
  }

  &.is-white {
    a,
    .icon {
      color: white;

      &:hover {
        color: $lighter;
      }
    }
  }

  &.is-dark {
    a,
    .icon {
      color: $dark;

      &:hover {
        color: color.adjust($dark, $lightness: -10%);
      }
    }
  }

  &.is-darker {
    a,
    .icon {
      color: $darker;

      &:hover {
        color: color.adjust($darker, $lightness: -10%);
      }
    }
  }
}
</style>
