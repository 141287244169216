<template>
  <div
    class="input-control"
    :class="{ 'is-loading': loading, 'has-icon-left': iconLeft, 'has-icon-right': iconRight }"
  >
    <MIcon v-if="iconLeft" class="is-left" :icon="iconLeft" :pack="iconLeftPack" />
    <!--INPUT DEFAULT-->
    <input
      v-if="type !== 'textarea'"
      v-model="localValue"
      class="input"
      :type="type"
      :maxlength="maxLength"
      :disabled="disabled"
      v-bind="$attrs"
      @blur="onBlur"
      @focus="onFocus"
      @input="updateValue"
    >

    <!--TEXTAREA-->
    <textarea
      v-if="type === 'textarea'"
      v-model="localValue"
      class="textarea"
      :disabled="disabled"
      :maxlength="maxLength"
      v-bind="$attrs"
      @blur="onBlur"
      @focus="onFocus"
      @input="updateValue"
    />

    <MIcon
      v-if="iconRight && !loading"
      class="is-right"
      :icon="iconRight"
      :pack="iconRightPack"
      @click="$emit('click-icon-right')"
    />

    <small
      v-if="maxLength && hasCounter && type !== 'number' && isFocused"
      class="input-counter"
    >
      {{ valueLength }} / {{ maxLength }}
    </small>
  </div>
</template>

<script>
import MIcon from '../Element/Icon.vue';
export default {
  name: 'MInput',
  components: { MIcon },
  status: 'ready',
  release: '1.0.0',
  inheritAttrs: false,
  props: {
    /**
     * v-model support
     */
    value: {
      type: [Number, String],
      default: ''
    },
    /**
     * Input type, can be `text`, `textarea`, `number`, `password`
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * Show char counter when input is focussed (must have a maxLength also)
     */
    hasCounter: {
      type: Boolean,
      default: false
    },
    /**
     * Max char length (can be used in combination with the counter)
     */
    maxLength: {
      type: [String, Number],
      default: 1000
    },
    /**
     * Input disabled state
     */
    disabled: Boolean,
    /**
     * Input loading state
     */
    loading: Boolean,
    /**
     * Icon left relative to input
     */
    iconLeft: {
      type: [Boolean, String],
      default: false
    },
    /**
     * Icon left pack
     */
    iconLeftPack: {
      type: String,
      default: 'fas'
    },
    /**
     * Icon right relative to input
     */
    iconRight: {
      type: [Boolean, String],
      default: false
    },
    /**
     * Icon right
     */
    iconRightPack: {
      type: String,
      default: 'fas'
    },
    /**
     * Set to true if the Icon right has a clickable action
     */
    iconRightClickable: Boolean
  },
  emits: ['click-icon-right', 'input', 'focus', 'blur'],
  data () {
    return {
      localValue: this.value,
      isFocused: false
    };
  },
  computed: {
    valueLength () {
      if (typeof this.localValue === 'string') {
        return this.localValue.length;
      } else if (typeof this.localValue === 'number') {
        return this.localValue.toString().length;
      }
      return 0;
    }
  },
  watch: {
    value (newValue) {
      this.localValue = newValue;
    }
  },
  methods: {
    onFocus (event) {
      this.isFocused = true;
      this.$emit('focus', event);
    },
    onBlur (event) {
      this.isFocused = false;
      this.$emit('blur', event);
    },
    updateValue (event) {
      this.$emit('input', event.target.value); // Emit input event to update the parent
    }
  }
};
</script>

<style scoped lang="scss">
@use '/submodules/vue-framework/src/scss/Colors.scss' as *;

input,
textarea {
  width: 100%;
  padding: 10px 22px 10px 10px;
  border: 1px solid $border-1;
  background-color: #fff;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
  color: $text-1;
  font-family: Roboto, sans-serif;
  &[disabled] {
    background-color: $lighter;
    cursor: not-allowed;
  }
  &::placeholder {
    color: $text-lighter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}

textarea {
  resize: vertical;
  height: 150px;
}

.input-control {
  position: relative;
  display: flex;
  flex-direction: column;

  //Input counter
  .input-counter {
    font-family: Roboto, sans-serif;
    margin-left: auto;
    position: absolute;
    right: 5px;
    bottom: 2px;
    font-size: 14px;
  }

  &.is-loading {
    color: transparent !important;
    cursor: wait;
    input,
    textarea {
      //padding-left: 40px;
      pointer-events: none;
    }
    &::after {
      border: 2px solid $light;
      border-right-color: transparent;
      border-top-color: transparent;
      right: 0.625em;
      top: 0.725em;
      z-index: 4;
      position: absolute;
      animation: spinAround 0.5s infinite linear;
      border-radius: 290486px;
      content: '';
      display: block;
      height: 1em;
      width: 1em;
    }
  }
  .icon {
    position: absolute;
    color: $light;
    font-size: 17px;
    display: inline-flex;
    height: 100%;
    align-items: center;
    &.is-left {
      margin-left: 10px;
    }
    &.is-right {
      right: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: ease all 100ms;
      color: $darker;
      &:hover {
        color: $dark;
      }
    }
  }
  &.has-icon-left input,
  &.has-icon-left textarea {
    padding-left: calc(1.2em + 10px);
  }
  &.has-icon-right input,
  &.has-icon-right textarea {
    padding-right: calc(1.2em + 10px);
  }
}
</style>
