<template>
  <div v-if="data.state.maglrLogin || data.state.providers.length === 0">
    <div class="fa-2x loading">
      <i class="fas fa-circle-notch fa-spin" />
    </div>

    <p>{{ $t('redirect to login') }}</p>
  </div>
</template>

<script>
import { useData } from '../services/Data';
import { reactive } from 'vue';

export default {
  name: 'RedirectPage',
  setup () {
    const data = useData();
    const state = reactive({
      loading: false
    });

    return {
      state,
      data
    };
  }
};
</script>

<style lang="scss" scoped>

.loading {

  color:#96A4AD;
  text-align:center;
  padding-top:25px;
  padding-bottom:50px;

}

</style>
