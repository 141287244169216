<template>
  <div class="field-container" :class="[labelPosition, { 'is-grouped': grouped }, validator.state]">
    <div
      v-if="labelLeft"
      class="label is-left"
      :class="[{ 'has-padding-top': hasLabelPaddingTop }, labelAlignment, labelVariant]"
    >
      <span>
        {{ labelLeft }}
      </span>
      <div v-if="tooltipText || $slots.hasOwnProperty('tooltip-content')" class="label-tooltip">
        <MTooltip :text="tooltipText || ''" :position="tooltipPosition">
          <template #tooltip-content>
            <slot name="tooltip-content" />
          </template>
          <MIcon icon="question-circle" />
        </MTooltip>
      </div>
      <div v-if="description || $slots.hasOwnProperty('description')" class="description">
        <slot name="description" />
        {{ description }}
      </div>
    </div>
    <div class="field-content">
      <div class="controls">
        <slot />
      </div>
      <div v-if="validator" class="meta-container">
        <div ref="meta" class="input-meta">
          <small v-if="validator.state !== '' && validator.message !== ''" class="input-message">
            {{ validator.message }}
          </small>
        </div>
      </div>
    </div>
    <div v-if="labelRight" class="label is-right">
      <span>
        {{ labelRight }}
      </span>
      <div v-if="tooltipText" class="label-tooltip">
        <MTooltip :text="tooltipText" :position="tooltipPosition">
          <MIcon icon="question-circle" />
        </MTooltip>
      </div>
    </div>
  </div>
</template>

<script>
import MTooltip from '../Element/Tooltip.vue';
import MIcon from '../Element/Icon.vue';
/**
 * <br>
 * This example uses the [Input](/#/Form/Input) form element but you can use any [Form Element](/#/Form) you want
 * In combination with the Field container.
 */
export default {
  name: 'MField',
  components: { MIcon, MTooltip },
  status: 'ready',
  release: '1.0.0',
  props: {
    /**
     * Label on the left side of the input
     */
    labelLeft: {
      default: false,
      type: [Boolean, String]
    },
    /**
     * Label on the right side of the input
     */
    labelRight: {
      default: false,
      type: [Boolean, String]
    },
    /**
     * Group the field children together (no padding or margin between them)
     */
    grouped: {
      default: false,
      type: Boolean
    },
    /**
     * The direction of the labels.
     * `is-top-bottom` places the label on the top and input on the bottom.
     * `is-left-right` places the label left and the input right
     */
    labelPosition: {
      default: 'is-left-right',
      type: String,
      validator: (v) => {
        return ['is-top-bottom', 'is-left-right'].indexOf(v) !== -1;
      }
    },
    /**
     * The alignment of the label. `is-top`, `is-center`, `is-bottom`, `is-left`, `is-right`
     */
    labelAlignment: {
      default: 'is-center',
      type: [String],
      validator: (v) => {
        return ['is-top', 'is-center', 'is-bottom', 'is-left', 'is-right'].indexOf(v) !== -1;
      }
    },
    /**
     * A validator for the field, must be an object. (prefer computed) `{ validation: false/true, state: 'is-danger'/'is-success', message: 'String' };`
     */
    validator: {
      default: false,
      type: [Object, Boolean]
    },
    /**
     * Text for tooltip, if false, no tooltip is shown. Else a question mark is shown after the Label.
     */
    tooltipText: {
      type: [Boolean, String],
      default: false
    },
    /**
     * Static position for the tooltip. See the [Tooltip](/#/Elements/Tooltip) docs
     */
    tooltipPosition: {
      type: String,
      default: 'is-dynamic'
    },
    /**
     * Label color variant, can be `is-light` or `is-dark`
     */
    labelVariant: {
      type: String,
      default: 'is-light'
    },
    /**
     * Field meta info, description
     */
    description: {
      type: [Boolean, String],
      default: false
    }
  },
  data () {
    return {
      value: '',
      newValue: '',
      isField: true,
      hasLabelPaddingTop: false
    };
  },
  mounted () {
    // Check if the slot contains a textarea because a textarea's label needs padding-top
    if (!this.defaultSlot()) {
      return;
    }
    // eslint-disable-next-line vue/require-slots-as-functions
    this.defaultSlot().forEach((vnode) => {
      if (!vnode.child) return vnode;
      if (typeof vnode.child !== 'object') {
        return vnode;
      }
      if (vnode.child.type !== 'textarea') {
        return vnode;
      }
      if (this.labelAlignment !== 'is-top') {
        return vnode;
      }

      this.hasLabelPaddingTop = true;
    });
  },
  methods: {
    defaultSlot () { // vue2+vue3 compatibility
      if (typeof this.$slots.default === 'function') {
        return this.$slots.default();
      } else {
        return this.$slots.default;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use '/submodules/vue-framework/src/scss/Colors.scss' as *;
@use '/submodules/vue-framework/src/scss/Mixin/Description.scss' as *;
@use '/submodules/vue-framework/src/scss/Mixin/Label.scss' as *;

.controls {
  position: relative;
  .field-container {
    &:first-child:not(:last-child) {
      margin-right: 5px;
      margin-left: 0;
    }
    &:last-child:not(:first-child) {
      margin-left: 5px;
      margin-right: 0;
    }
    &:first-child:last-child {
      margin: 0;
    }
    margin: 0 5px;
  }
}

.field-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    &::v-deep {
      .button-container {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.is-top-bottom {
    flex-direction: column;
    justify-content: center;
    .label {
      flex: 1 1 auto; // IE 11 fix
      margin-bottom: 5px;
    }
    .field-content {
      flex: 1 1 auto; // IE 11 fix
    }
  }

  &.is-left-right {
    flex-direction: row;
  }

  &.is-left {
    margin-right: auto;
  }

  &.is-right {
    margin-left: auto;
  }

  &.is-horizontal {
    .controls {
      flex-direction: row;
    }
  }

  &.is-vertical {
    .controls {
      flex-direction: column;
    }
  }

  .field-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
  }

  .controls {
    display: flex;
    flex-direction: row;
  }

  .meta-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    left: 0;
  }

  @include label();
  .label {
    position: relative;
    flex: 1 1 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      line-height: 16px;
    }
    img {
      margin-left: 5px;
    }
    &.has-padding-top {
      padding-top: 13px;
      .description {
        margin-top: calc(5px + 13px);
      }
    }
    &.is-top,
    &.is-left {
      align-self: flex-start;
    }
    &.is-center {
      align-self: center;
    }
    &.is-bottom,
    &.is-right {
      align-self: flex-end;
    }
    .label-tooltip {
      display: inline-flex;
      margin-left: 10px;
      line-height: 16px;
      color: #b0bcc3;
    }
    @include description();
    .description {
      position: absolute;
      top: 15px;
      margin-top: 5px;
      display: inline-flex;
      align-items: center;
      .icon {
        font-size: 14px;
        color: $secondary;
        margin-right: 7.5px;
      }
    }
  }

  //Messages
  .input-meta {
    flex: 1 1 50%;
    font-family: Roboto, sans-serif;
    padding-left: 10px;
    .input-message {
      display: inline-flex;
      font-size: 13px;
    }
  }

  .input-control {
    flex: 1 1 50%;
    &:first-child:not(:last-child) {
      margin: 0 5px 0 0;
    }

    &:last-child:not(:first-child) {
      margin: 0 0 0 5px;
    }

    &:first-child:last-child {
      margin: 0;
    }

    width: 100%;
    margin: 0 5px;
  }

  $noticeColorsLight: (
      (is-success, $success-light-text, $success-light-background),
      (is-danger, $danger-light-text, $danger-light-background),
      (is-warning, $warning-light-text, $warning-light-background),
      (is-info, $info-text-light, $info-background-light)
  );

  @each $name, $text, $background in $noticeColorsLight {
    &.#{$name} {
      .input-meta {
        background-color: $background;
      }
      .input-label {
        color: $text;
      }
      .input-message {
        font-weight: 700;
        color: $text;
      }
      &::v-deep {
        input:not(.vs__search),
        .v-select,
        textarea,
        select,
        .checkbox-wrapper {
          border: 1px $text solid !important;
        }
      }
    }
  }

  //Grouped input fields, stick together
  &.is-grouped {
    .input-control {
      margin: 0;

      &:first-child:not(:last-child) {
        .input {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right-width: 0.5px;
        }

        margin: 0;
      }

      &:last-child:not(:first-child) {
        .input {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-left-width: 0.5px;
        }

        margin: 0;
      }

      .input {
        border-radius: 0;
        border-left-width: 0.5px;
        border-right-width: 0.5px;
      }
    }
  }
}
</style>
