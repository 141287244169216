<template>
  <div class="login-container">
    <div class="embed-container">
      <div v-if="!state.whitelabel" :data-maglrEmbed="maglrEmbedData" data-type="static" style="position:absolute; width:100%; height:100%; ">
        <iframe :src="maglrEmbedUrl" style="position:absolute;width:100%;height:100%;" />
      </div>
    </div>
    <div class="login-overlay" :class="{'is-centered': state.whitelabel}">
      <div class="popup">
        <div v-if="!whitelabel" class="maglr-logo" />
        <div class="maglr-login">
          <div class="login-form">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <div v-if="state.whitelabel" class="powered-by">
      <div class="maglr-logo" />
    </div>
  </div>
</template>

<script>
import { provideApi } from './services/Api';
import { provideData } from './services/Data';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

export default {
  name: 'App',
  props: {
    maglrLogin: {
      default: false,
      type: Boolean
    },
    whitelabel: {
      default: false,
      type: Boolean
    },
    providers: {
      default: () => [],
      type: Array
    }
  },
  setup (props) {
    const api = provideApi();
    const { state } = provideData();
    const route = useRoute();
    Object.assign(state, props);
    let maglrEmbed = 'jfotnh0koc';

    const maglrEmbedUrl = computed(() => {
      return 'https://embed.maglr.com/' + maglrEmbedData.value + '?disable-stats=1';
    });

    const maglrEmbedData = computed(() => {
      if (typeof route.name === 'undefined') {
        maglrEmbed = 'jfotnh0koc';
        return maglrEmbed;
      }
      switch (route.name) {
        case 'Redirect':
          break;
        case 'ResetPassword':
        case 'NewPassword':
          maglrEmbed = 'm0mb80mu5q';// embed-wachtwoord-vergeten
          break;
        case 'PasswordConfirm':
          maglrEmbed = 'zywlfmgsck';// confirm embed
          break;
        case 'ConfirmTwoFactor':
        case 'ActivateTwoFactor':
        case 'DeactivateTwoFactor':
        case 'RecoveryCodeUsed':

          maglrEmbed = 'ere57o1pgg';// Two-factor embed
          break;
        case 'ActivateSingleSignOn':
        case 'LoginSingleSignOn':
        case 'FailSingleSingOn':
        case 'SuccessSingleSingOn':
        case 'DeactivateSingleSingOn':
          maglrEmbed = 'tqrzjead3m';// Single Sign On embed
          break;
        case 'Login':
        case 'ValidateTwoFactor':
        default:
          maglrEmbed = 'jfotnh0koc';// Login embed
          break;
      }
      return maglrEmbed;
    });

    // get user
    api.getUser().then(r => {
      state.email = r.data.user.email;
      state.user = r.data.user;
      state.singleSignOnProvider = r.data.single_sign_ex_provider;
      state.hasSingleSignOn = r.data.has_single_sign_on;
      state.needSingleSignOn = r.data.single_sign_on;
      if (r.data.two_factor_activate_skip != null && r.data.two_factor_activate_skip !== '') {
        state.twoFactorSkipAllowed = r.data.two_factor_activate_skip;
        state.twoFactorSkipAllowedCount = r.data.two_factor_activate_skip_count;
      }
      if (r.data.single_sign_on_skip != null && r.data.single_sign_on_skip !== '') {
        state.singleSignOnSkipAllowed = r.data.single_sign_on_skip;
        state.singleSignOnSkipAllowedCount = r.data.single_sign_on_skip_count;
      }
      state.twoFactor = r.data.two_factor;
      if (typeof r.data.single_sign_on_providers !== 'undefined') {
        state.providers = r.data.single_sign_on_providers;
      }
      if (typeof r.data.lang !== 'undefined') {
        window.i18n.global.locale = r.data.lang;
      }
      state.login_success = true;
    }).catch(e => {
      console.error('api.getUser', e.data?.message); // eslint-disable-line no-console
    }).finally(() => {
      state.loadingUser = false;
    });

    return {
      state,
      maglrEmbedData,
      maglrEmbedUrl
    };
  }
};
</script>

<style lang="scss">
@use "/submodules/vue-framework/src/scss/FontFaces.scss" as *;
@use "/submodules/vue-framework/src/scss/Main.scss" as *;

form {
    .input-control {
        width: 100%;
    }

    margin-top:25px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loginheader {
  font-size:25px;
  line-height:1.2em;
  margin-bottom:15px;

}

.maglr-logo {
  width: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  height: 35px;
  margin-bottom: 35px;
  background-image: url("../img/logo_2019_green.svg");
}

::placeholder {opacity:0.5;}

.error{
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  background-color: #f1dada;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #950202;
  border: 1px solid;
}

.login-form {
  font-size: 16px;

  .field-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.helplink {
  margin-top:3px;
  display: block;
}

.links {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    font-size: 16px;
    justify-content: center;
}

@media (max-width: 500px) {
    .login-overlay {
        margin-left: 0 !important;
    }
}

.powered-by {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .maglr-logo {
        width: 150px;
        height: 50px;
        background-image: url("../img/powered-by-maglr.svg");
    }
}

.login-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;

  // Position absolute background once instead of all child elements.
  .embed-container {
      position: absolute;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      top: 0;
      left: 0;
  }
  .login-overlay {
    width: 460px;
    height: auto;
    padding: 35px 40px 45px 40px;
    background-color: #FFF;
    border-radius: 3px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.10);
    margin-left: 100px;
    overflow: auto;
    font-family: Roboto, sans-serif;

    &.is-centered {
      margin-left: auto;
      margin-right: auto;

      .links {
        justify-content: center;
      }
    }

    p {
      line-height:1.5em;
      color:#6A6A6A;
    }

    .link {
      color:#717171 !important ;
    }

    .link-container .icon {

      color:#717171 !important ;
    }

  }
}

// IPAD
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    .login-container {
        justify-content: center;

        .login-overlay {
            margin-left: 0;
        }
    }
}

// iPhone X
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
    .login-container {
        justify-content: center;
        align-items: flex-start;

        .login-overlay {
            width: 100%;
            height: 100%;
            margin-left: 0;
        }
    }
}

// iPhones
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2) {
    .login-container {
        justify-content: center;
        align-items: flex-start;

        .login-overlay {
            width: 100%;
            height: 100%;
            margin-left: 0;
        }
    }
}

// Google pixel
@media screen
and (min-device-width: 411px)
and (max-device-width: 823px) {
    .login-container {
        justify-content: center;
        align-items: flex-start;

        .login-overlay {
            width: 100%;
            height: 100%;
            margin-left: 0;
        }
    }
}

// Samsung
@media screen
and (min-device-width: 360px)
and (max-device-width: 640px) {
    .login-container {
        justify-content: center;
        align-items: flex-start;

        .login-overlay {
            width: 100%;
            height: 100%;
            margin-left: 0;
        }
    }
}
</style>
