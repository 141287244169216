<template>
  <div v-if="data.state.maglrLogin || data.state.providers.length === 0">
    <h3 class="loginheader">
      {{ $t('recovery code used title') }}
    </h3>

    <div class="intro">
      <p>{{ $t('recovery code used') }}</p>
    </div>

    <div class="form-controls">
      <MButton size="is-large" variant="is-accent-2" to="/login/redirect" @click="toDashboard">
        {{ $t('goto_dashboard') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { useData } from '../../services/Data';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'RedirectPage',
  components: { MButton },
  setup () {
    const data = useData();
    const router = useRouter();
    const state = reactive({
      errors: [],
      loading: false
    });

    function toDashboard () {
      data.state.login_success = true;
      if (data.state.redirectAfterLogin !== false) {
        router.push(data.state.redirectAfterLogin);
        data.state.redirectAfterLogin = false;
        return;
      }
      router.push('/login/redirect');
      window.location.href = '/login/redirect?r=/settings/profile';
    }

    return { state, toDashboard, data };
  }
};
</script>

<style lang="scss" scoped>

 .intro {

    padding-bottom:25px;

 }

 .button-container {
    &::v-deep(.button) {
        width: 100%;
    }
}

</style>
