<template>
  <h3 class="loginheader">
    {{ $t('verify two factor authentication title') }}
  </h3>
  <div class="login-intro">
    <p>{{ $t('verify two factor authentication description') }}</p>
    <a class="helplink" target="_blank" :href="$t('help-url')">{{ $t('help') }}</a>
  </div>
  <form @submit.prevent>
    <MField
      :validator="codeValidator"
      label-variant="is-dark"
      :label-left="$t('code label')"
      label-alignment="is-top"
      label-position="is-top-bottom"
    >
      <MInput
        v-model="state.code"
        name="code"
        :placeholder="$t('code')"
        @keyup.enter="submit"
      />
    </MField>
  </form>
  <div class="form-controls">
    <div class="links">
      <MLink is="router-link" to="/login/" variant="is-darker" target="_self" icon-left="long-arrow-left">
        {{ $t('back') }}
      </MLink>
    </div>
    <MButton size="is-large" :disabled="!state.showSubmitButton" variant="is-accent-2" @click="submit">
      {{ $t('confirm code button') }}
    </MButton>
  </div>
</template>
<script>
import { useData } from '../../services/Data';
import { useApi } from '../../services/Api';
import { useRouter, useRoute } from 'vue-router';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import MField from '../../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../../submodules/vue-framework/src/components/Form/Input.vue';
import MLink from '../../../../submodules/vue-framework/src/components/Element/Link.vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'ValidateTwoFactor',
  components: { MButton, MLink, MInput, MField },
  setup () {
    const data = useData();
    const api = useApi();
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      code: '',
      errors: {},
      showSubmitButton: true,
      loading: false
    });

    api.getUserLang({ email: data.state.email }).then(r => {
      if (typeof r.data.lang !== 'undefined') {
        window.i18n.global.locale = r.data.lang;
      }
    }).catch(e => {
      console.error('api.getUser', e.data?.message); // eslint-disable-line no-console
    });

    const skipAllowed = computed(() => {
      return data.state.twoFactorSkipAllowed;
    });
    const skipAllowedCount = computed(() => {
      return data.state.twoFactorSkipAllowedCount;
    });
    const dashUri = computed(() => {
      return data.state.dashUri;
    });
    const codeValidator = computed(() => {
      if (state.errors.code) {
        return {
          message: state.errors.code,
          state: 'is-danger',
          validation: false
        };
      }
      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });

    if (data.state.password === false) {
      router.push('/login');
    }

    async function submit () {
      if (state.code.length < 6) {
        state.errors.code = i18n.t('error invalid code');
        return;
      }
      state.errors = {};
      state.loading = true;
      let payload = { code: state.code };
      if (state.code.length >= 20) {
        payload = { recovery_code: state.code };
      }
      api.twoFactorChallenge(payload)
        .then(response => {
          if (state.code.length >= 20) {
            router.push('/login/twofactor/recovery');
            return;
          }
          data.state.login_success = true;
          if (data.state.redirectAfterLogin !== false) {
            router.push(data.state.redirectAfterLogin);
            data.state.redirectAfterLogin = false;
            return;
          }

          router.push('/login/redirect');
          window.location.href = '/login/redirect';
        })
        .catch(e => {
          state.errors.code = e.response.data.message;
          if (e.response.data.message === 'Too Many Attempts.') {
            state.showSubmitButton = false;
            return;
          }
          if (e.response.data.error === 'Unauthenticated.' || e.response.data.message === 'The given data was invalid.') {
            if (data.state.login_success) {
              api.login({ email: data.state.email, password: data.state.password });
            } else {
              data.state.redirectAfterLogin = route.path;
              router.push('/login');
            }
          }
        })
        .finally(() => {
          state.loading = false;
        });
    }

    return {
      dashUri,
      skipAllowed,
      skipAllowedCount,
      codeValidator,
      state,
      submit,
      data
    };
  }
};
</script>

<style lang="scss" scoped>

.form-controls {
  display: flex;
  justify-content: space-between;
  margin-top:15px;

}

.login-intro {

  padding-bottom:25px;
}

.links {margin-left:0px;}

</style>
