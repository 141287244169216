<template>
  <div class="intro">
    <h3 class="loginheader">
      {{ $t('activate_single_sign_on_title') }}
    </h3>
    <p>{{ activateDescription }}</p>
    <a class="helplink" target="_blank" :href="$t('help-url-sso')">{{ $t('help') }}</a>
  </div>

  <div v-if="data.state.providers.length > 0" class="thirdparty-login">
    <div class="oauth-providers">
      <MButton
        v-for="oauthProvider in data.state.providers"
        :key="oauthProvider.name"
        :icon-pack="oauthProvider.iconPack"
        :icon-left="oauthProvider.icon"

        variant="is-accent-2"
        size="is-large"
        @click="() => openUrl(oauthProvider.destination)"
      >
        {{ $t('connect_with') }} {{ oauthProvider.name }}
      </MButton>
    </div>
  </div>
  <MLink v-if="skipAllowed" variant="is-darker" icon-right="long-arrow-right" @click="skip">
    {{ $t('Ik will deze stap nu overslaan') }}
    ({{ data.state.singleSignOnSkipAllowedCount }})
  </MLink>
  <MLink v-if="dashboardRoute" variant="is-darker" icon-right="long-arrow-left" @click="back">
    {{ $t('back') }}
  </MLink>
</template>

<script>
import { useData } from '../../services/Data';
import { useRoute, useRouter } from 'vue-router';
import { reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';
import MLink from '../../../../submodules/vue-framework/src/components/Element/Link.vue';

export default {
  name: 'ActivateSingleSignOn',
  components: { MLink, MButton },
  setup () {
    const data = useData();
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    const state = reactive({
      loading: false
    });
    const dashboardRoute = computed(() => {
      return route.name === 'UserActivateSingleSignOn';
    });
    const activateDescription = computed(() => {
      if (data.state.needSingleSignOn) {
        return i18n.t('mandatory_single_sign_on_description').replace('[[email]]', data.state.email);
      }
      return i18n.t('activate_single_sign_on_description').replace('[[email]]', data.state.email);
    });
    function openUrl (url) {
      window.location.href = url;
    }
    function skip () {
      router.push('/login/redirect');
      window.location.href = '/login/sso/skip';
    }
    function back () {
      router.push('/login/redirect');
      window.location.href = '/login/redirect';
    }
    const skipAllowed = computed(() => {
      return !dashboardRoute.value && data.state.singleSignOnSkipAllowed;
    });
    const skipAllowedCount = computed(() => {
      return data.state.singleSignOnSkipAllowedCount;
    });
    return {
      openUrl,
      skipAllowed,
      skipAllowedCount,
      activateDescription,
      state,
      data,
      skip,
      back,
      dashboardRoute
    };
  }
};
</script>

<style lang="scss" scoped>

.intro{
  padding-bottom: 36px;
}

.button-container {
    &::v-deep(.button) {
        width: 100%;
    }
}

.link-container {
  margin-top:35px;
  align-items:end;
  float:right;
}

.oauth-providers {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .button-container {
    width: 100%;
    margin-bottom: 20px;

    &::v-deep(.oauth-button) {
      width: 100%;
      justify-content: flex-start;
      background-color: transparent;

    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

</style>
