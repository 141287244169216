import axios from 'axios';
import { inject, provide } from 'vue';

export const ApiKey = Symbol('ApiKey');

export function createApi () {
  const instance = axios.create({
    baseURL: '/',
    withCredentials: true,
    maxRedirects: -1,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  async function login (data) {
    return instance.post('/api/login', data, { maxRedirects: 0 });
  }
  async function hasConfirmPassword () {
    return instance.get('api/user/confirmed-password-status', {}, { maxRedirects: 0 });
  }
  async function confirmPassword (data) {
    return instance.post('/api/user/confirm-password', data, { maxRedirects: 0 });
  }
  async function twoFactorQr () {
    return instance.get('/api/user/two-factor-qr-code', {}, { maxRedirects: 0 });
  }
  async function twoFactorAuth (data) {
    return instance.post('/api/user/two-factor-authentication', data, { maxRedirects: 0 });
  }
  async function twoFactorAuthDeactivate () {
    return instance.delete('/api/user/two-factor-authentication', {}, { maxRedirects: 0 });
  }
  async function twoFactorRecoveryCodes (data) {
    return instance.get('/api/user/two-factor-recovery-codes', data, { maxRedirects: 0 });
  }
  async function getTwoFactorChallenge () {
    return instance.get('/api/two-factor-challenge', {}, { maxRedirects: 0 });
  }
  async function twoFactorChallenge (data) {
    return instance.post('/api/two-factor-challenge', data, { maxRedirects: 0 });
  }
  async function twoFactorConfirm (data) {
    return instance.post('/api/two-factor-confirm', data, { maxRedirects: 0 });
  }
  async function getUser () {
    return instance.get('/api/login/user', {}, { maxRedirects: 0 });
  }
  async function getUserLang (data) {
    return instance.post('/api/login/user/lang', data, { maxRedirects: 0 });
  }
  async function getUserByPasswordToken (data) {
    return instance.post('api/user/token/resetpassword', data, { maxRedirects: 0 });
  }
  async function getUserByConfirmEmailToken (data) {
    return instance.post('api/user/token/confirmemail', data, { maxRedirects: 0 });
  }
  async function disableSingeSignOn (data) {
    return instance.post('/api/user/sso-delete/all', data, { maxRedirects: 0 });
  }
  async function requestLostpassword (data) {
    return instance.post('/api/lostpassword', data, { maxRedirects: 0 });
  }
  async function setPassword (data) {
    return instance.post('/api/user/password', data, { maxRedirects: 0 });
  }
  async function confirmEmail (data) {
    return instance.post('/api/user/confirm-email', data, { maxRedirects: 0 });
  }

  return {
    getUser,
    getUserLang,
    getUserByPasswordToken,
    getUserByConfirmEmailToken,
    login,
    requestLostpassword,
    setPassword,
    twoFactorChallenge,
    twoFactorConfirm,
    getTwoFactorChallenge,
    twoFactorQr,
    hasConfirmPassword,
    confirmPassword,
    twoFactorAuth,
    twoFactorAuthDeactivate,
    twoFactorRecoveryCodes,
    disableSingeSignOn,
    confirmEmail
  };
}

export function provideApi () {
  const inst = createApi();
  provide(ApiKey, inst);
  return inst;
}

export function useApi () {
  const inst = inject(ApiKey);

  if (!inst) throw new Error('Api not provided');

  return inst;
}
