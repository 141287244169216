<template>
  <div>
    <div v-if="data.state.loadingUser">
      Loading information
    </div>
    <div v-else>
      <!-- Create new password -->
      <div v-if="state.status === 'deactivate'">
        <div v-if="data.state.hasSingleSignOn" class="maglr-login">
          <div class="intro">
            <h3 class="loginheader">
              {{ $t('deactivate_single_sign_on_title') }}
            </h3>
            <p>{{ deactivateDescription }}</p>
          </div>
          <form @submit.prevent>
            <input class="hidden" type="text" name="username" :value="data.state.email">
            <MField
              label-variant="is-dark"
              :validator="passwordValidator"
              :label-left="$t('new_password')"
              label-alignment="is-top"
              label-position="is-top-bottom"
            >
              <MInput
                v-model="state.password"
                autocomplete="new-password"
                name="password"
                type="password"
                :placeholder="$t('new_password')"
                @keyup.enter="confirm()"
                @keyup="checkError()"
              />
            </MField>
          </form>
          <div class="form-controls">
            <MButton size="is-large" variant="is-accent-3" @click="redirect">
              {{ $t('cancel_button') }}
            </MButton>
            <span>&nbsp;</span>
            <MButton class="right" :loading="state.loading" size="is-large" variant="is-accent-2" @click="confirm">
              {{ $t('confirm_button') }}
            </MButton>
          </div>
        </div>

        <div v-else>
          <!-- Error : User has no SSO -->
          <div class="error">
            {{ $t('error_user_no_single_sign_on') }}
          </div>
          <div class="form-controls">
            <MButton class="right" size="is-large" variant="is-primary" @click="redirect">
              {{ $t('back') }}
            </MButton>
          </div>
        </div>
      </div>

      <!-- Ontkoppeling voltooid -->
      <div v-if="state.status === 'success'">
        <div class="intro">
          <h3 class="loginheader">
            {{ $t('deactivated_single_sign_on_title') }}
          </h3>
          <p>{{ deactivatedDescription }}</p>
        </div>

        <div class="form-controls">
          <MButton class="right" :loading="state.loading" size="is-large" variant="is-accent-2" @click="redirect">
            {{ $t('goto_dashboard') }}
          </MButton>
        </div>
      </div>

      <div v-if="state.status === 'error'">
        <div class="error">
          {{ $t('error_single_sign_on') }}
          <p>{{ state.errors.password }}</p>
        </div>
        <div class="form-controls">
          <MButton class="right" size="is-large" variant="is-primary" @click="redirect">
            {{ $t('back') }}
          </MButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useData } from '../../services/Data';
import { useApi } from '../../services/Api';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { computed, reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import MField from '../../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../../submodules/vue-framework/src/components/Form/Input.vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'DeactivateSingleSignOn',
  components: { MButton, MInput, MField },
  setup () {
    const data = useData();
    const api = useApi();
    const router = useRouter();
    const i18n = useI18n();
    const state = reactive({
      status: 'deactivate',
      loading: false,
      password: '',
      errors: { password: '' }
    });
    const passwordRequired = helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);
    const rules = {
      password: { required, minLength: minLength(8), passwordRequired } // Matches state.password
    };
    const v$ = useVuelidate(rules, state);
    // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
    const passwordValidator = computed(() => {
      if (state.errors.password) {
        return {
          message: state.errors.password,
          state: 'is-danger',
          validation: false
        };
      }
      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });
    const deactivateDescription = computed(() => {
      return i18n.t('deactivate_single_sign_on_description').replace('[[email]]', data.state.email);
    });
    const deactivatedDescription = computed(() => {
      return i18n.t('deactivate_single_sign_on_description').replace('[[email]]', data.state.email);
    });

    function redirect () {
      router.push('/login/redirect');
      window.location.href = '/login/redirect';
    }

    function checkError () {
      if (typeof state.errors.password !== 'undefined' && state.errors.password !== '') {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (typeof state.password === 'string' && regex.test(state.password)) {
          state.errors.password = '';
        }
      }
    }

    function confirm () {
      state.errors = {};
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (typeof state.password === 'string' && !regex.test(state.password)) {
        state.errors.password = i18n.t('password_requirements');
        return;
      }
      state.loading = true;
      api.disableSingeSignOn({ password: state.password }).then(() => {
        state.status = 'success';
      }).catch(e => {
        if (typeof e.response.data.exception !== 'undefined') {
          state.status = 'error';
          state.errors.password = e.response.data.exception;
        }
        if (typeof e.response.data.message !== 'undefined') {
          state.errors.password = e.response.data.message;
        }
      }).finally(() => {
        state.loading = false;
      });
    }
    return {
      passwordValidator,
      deactivateDescription,
      deactivatedDescription,
      confirm,
      checkError,
      redirect,
      state,
      data,
      v$
    };
  }
};
</script>

<style lang="scss" scoped>

.intro{
  padding-bottom: 35px;
}
.hidden {
  display: none;
}

.form-controls {
  display: flex;
  justify-content: space-between;
  margin-top:15px;

}

.login-form {
  &::v-deep(.input-control) {
    width: 100%;
  }
}

</style>
