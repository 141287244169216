<template>
  <div>
    <div class="intro">
      <h3 class="loginheader" v-html="state.title" />
      <p v-html="state.description" />
    </div>
    <div v-if="data.state.providers.length > 0" class="thirdparty-login">
      <div class="oauth-providers">
        <MButton
          v-for="oauthProvider in data.state.providers"
          :key="oauthProvider.name"
          :icon-pack="oauthProvider.iconPack"
          :icon-left="oauthProvider.icon"

          variant="is-accent-2"
          size="is-large"
          @click="() => openUrl(oauthProvider.destination)"
        >
          {{ $t('login met') }} {{ oauthProvider.name }}
        </MButton>
      </div>
    </div>
    <div class="form-controls">
      <div class="links">
        <!-- Hoort deze logica SKIP in de fail pagina ? -->
        <MLink v-if="skipAllowed" variant="is-darker" icon-left="chevron-right" @click="skip">
          {{ $t('Ik will deze stap nu overslaan') }}
          ({{ data.state.singleSignOnSkipAllowedCount }})
        </MLink>
        <MLink to="javascript::void(0)" variant="is-darker" target="_self" icon-left="long-arrow-left" @click="back">
          {{ $t('back') }}
        </MLink>
      </div>
    </div>
  </div>
</template>

<script>
import { useData } from '../../services/Data';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { reactive, computed } from 'vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';
import MLink from '../../../../submodules/vue-framework/src/components/Element/Link.vue';

export default {
  name: 'FailtSingleSignOn',
  components: { MLink, MButton },
  props: {
    error: {
      default: '',
      type: String
    }
  },
  setup: function () {
    const data = useData();
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    const state = reactive({
      loading: false,
      title: 'error',
      description: 'There was a error',
      error: 'mail'
    });
    state.description = computed(() => {
      if (route.query?.error === 'mail' ||
        route.query?.error === 'new_same_email_policy' || route.query?.error === 'exist_same_email_policy') {
        return replace(i18n.t('fail_email_single_sign_on_description'));
      } else if (route.query?.error === 'user_not_found') {
        return replace(i18n.t('fail_user_not_found_single_sign_on_description'));
      } else if (route.query?.error === 'exist_sso_same_email_policy') {
        return replace(i18n.t('fail_user_sso_in_user_disconnect_other_description'));
      } else if (route.query?.error === 'existsso_ssomanditory') {
        return replace(i18n.t('fail_user_sso_in_user_use_other_description'));
      } else if (route.query?.error === 'existsso') {
        return replace(i18n.t('fail_user_sso_in_user_use_or_disconnect_other_description'));
      }
      return i18n.t('fail_single_sign_on_description');
    });
    state.title = computed(() => {
      if (route.query?.error === 'mail' ||
        route.query?.error === 'new_same_email_policy' || route.query?.error === 'exist_same_email_policy') {
        return replace(i18n.t('fail_email_single_sign_on_title'));
      } else if (route.query?.error === 'user_not_found') {
        return replace(i18n.t('fail_user_not_found_single_sign_on_title'));
      } else if (route.query?.error === 'exist_sso_same_email_policy') {
        return replace(i18n.t('fail_user_sso_in_user_disconnect_other_title'));
      } else if (route.query?.error === 'existsso_ssomanditory') {
        return replace(i18n.t('fail_user_sso_in_user_use_other_title'));
      } else if (route.query?.error === 'existsso') {
        return replace(i18n.t('fail_user_sso_in_user_use_or_disconnect_other_title'));
      }
      return i18n.t('fail_single_sign_on_title');
    });

    function replace (value) {
      if (typeof value !== 'string') {
        return value;
      }
      const p = document.createElement('p');
      p.textContent = route.query?.email;
      const ssoEmail = p.innerHTML;
      p.textContent = data.state?.email;
      const email = p.innerHTML;
      return value.replace('[[email]]', email)
        .replace('[[sso_email]]', ssoEmail);
    }

    function back () {
      if (route.name === 'FailUserSingleSingOn') {
        router.push('/login/redirect');
        window.location.href = '/login/redirect';
      } else if (typeof data.state.user?.user_id !== 'undefined') {
        router.push('/login/redirect');
        window.location.href = '/login/redirect';
      } else {
        router.push('/login');
      }
    }

    function openUrl (url) {
      window.location.href = url;
    }

    function skip () {
      router.push('/login/redirect');
      window.location.href = '/login/sso/skip';
    }

    const skipAllowed = computed(() => {
      if (route.name === 'FailUserSingleSingOn' || route.name === 'FailUserSingleSingOnMail') {
        return false;
      }
      return data.state.singleSignOnSkipAllowed;
    });
    const skipAllowedCount = computed(() => {
      return data.state.singleSignOnSkipAllowedCount;
    });

    return {
      openUrl,
      skipAllowed,
      skipAllowedCount,
      state,
      back,
      data,
      skip
    };
  }

};
</script>

<style lang="scss" scoped>

.button-container {
    &::v-deep(.button) {
        width: 100%;
    }
}

.intro{
  padding-bottom: 35px;
}

.links {
  margin-left:0px;
}

.oauth-providers {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .button-container {
    width: 100%;
    margin-bottom: 20px;

    &::v-deep(.oauth-button) {
      width: 100%;
      justify-content: flex-start;
      background-color: transparent;

    }

    &:last-child {
      margin-bottom: 45px;
    }
  }
}

</style>
