import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import VueCookies from 'vue3-cookies';
import router from './router';
import en from '../lang/en.json';
import nl from '../lang/nl.json';

const i18n = createI18n({
  locale: window.maglrLocale || 'en',
  fallbackLocale: 'en',
  sync: true,
  messages: { en, nl }
});
window.i18n = i18n;

const rootProps = JSON.parse(document.getElementById('rootProps').textContent);

const app = createApp(App, rootProps);
app.use(i18n);
app.use(router);
app.use(VueCookies, {
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None'
});
app.mount('#app');
