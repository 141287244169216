<template>
  <div class="activate-2fa">
    <h3 class="loginheader">
      {{ $t('activate two factor authentication title') }}
    </h3>
    <div class="qr-box">
      <img :loading="state.loading" class="qrcode center" :src="state.qrcode">
      <div>
        <p>{{ $t('activate two factor authentication description') }}</p>
        <a target="_blank" class="helplink" :href="$t('help-url')">{{ $t('help') }}</a>
      </div>
    </div>
    <form @submit.prevent>
      <MField
        :validator="codeValidator"
        label-variant="is-dark"
        :label-left="$t('code label')"
        label-alignment="is-top"
        label-position="is-top-bottom"
      >
        <MInput
          v-model="state.code"
          name="code"
          :placeholder="$t('code')"
          @keyup.enter="confirm"
        />
      </MField>
    </form>
    <div class="form-controls">
      <MButton :loading="state.loading" size="is-large" variant="is-accent-2" @click="confirm">
        {{ $t('confirm code button') }}
      </MButton>
      <MButton size="is-large" variant="is-accent-3" @click="cancel">
        {{ $t('cancel_button') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { useData } from '../../services/Data';
import { useApi } from '../../services/Api';
import { useRoute, useRouter } from 'vue-router';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import MField from '../../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../../submodules/vue-framework/src/components/Form/Input.vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'ActivateTwoFactor',
  components: { MButton, MInput, MField },
  setup () {
    const data = useData();
    const api = useApi();
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      code: '',
      errors: {},
      qrcode: '',
      show: 'login',
      loading: true
    });
    const dashboardRoute = computed(() => {
      return route.name === 'UserActivateTwoFactor';
    });
    const skipAllowed = computed(() => {
      return data.state.twoFactorSkipAllowed;
    });
    const skipAllowedCount = computed(() => {
      return data.state.twoFactorSkipAllowedCount;
    });
    const codeValidator = computed(() => {
      if (state.errors.code) {
        return {
          message: state.errors.code,
          state: 'is-danger',
          validation: false
        };
      }
      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });
    async function confirmPassword () {
      if (data.state.password_confirmed === false) {
        await api.hasConfirmPassword().then(
          response => {
            data.state.password_confirmed = response.data.confirmed === true;
          }
        ).catch(error => {
          if (error.response.data.error === 'Unauthenticated.') {
            data.state.redirectAfterLogin = route.path;
            router.push('/login');
          }
          console.error('error response in has confirm call.', error.response.data.message); // eslint-disable-line no-console
          state.errors.code = error.response.data.message;
        });
        if (data.state.password_confirmed === false) {
          if (data.state.password !== false) {
            await api.confirmPassword({ password: data.state.password }).then(
              () => {
                data.state.password_confirmed = true;
              }
            ).catch(error => {
              if (error.response.data.error === 'Unauthenticated.') {
                data.state.redirectAfterLogin = route.path;
                router.push('/login');
              }
              console.error('error response in confirm call.', error.response.data.message); // eslint-disable-line no-console
              state.errors.code = error.response.data.message;
            });
          } else {
            data.state.nextstep = route.path;
            await router.push('/login/confirm');
            return;
          }
        }
      }
      await activateTwoFactor();
    }
    async function activateTwoFactor () {
      if (data.state.twoFactor === false) {
        await api.twoFactorAuth().catch(error => {
          state.errors.code = error.response.data.message;
        });
      }
      await getTwoFactorActivateQr();
    }

    async function getTwoFactorActivateQr () {
      state.loading = true;
      await api.twoFactorQr()
        .then(response => {
          if (response.data.svg !== '') {
            state.qrcode = 'data:image/svg+xml;base64,' + window.btoa(response.data.svg);
            state.loading = false;
          }
        }
        ).catch(error => {
          state.errors.code = error.response.data.message;
        });
    }
    confirmPassword();

    async function confirm () {
      state.errors.code = false;

      state.code = state.code.trim();
      if (state.code.length !== 6) {
        state.errors.code = i18n.t('error invalid code');
        return;
      }
      state.loading = true;

      await api.twoFactorConfirm({ code: state.code })
        .then(response => {
          if (response.data.confirm !== true) {
            state.errors.code = i18n.t('error invalid code');
            state.loading = false;
            return;
          }
          router.push('/login/twofactor/confirm');
        })
        .catch(error => {
          if (error.response.status === 204) {
            data.state.nextstep = route.path;
            router.push('/login/twofactor/confirm');
            return;
          }
          state.errors.code = error.response.data.message;
          if (data.state.login_success) {
            api.login({ email: data.state.email, password: data.state.password });
          } else {
            data.state.redirectAfterLogin = route.path;
            router.push('/login');
          }
        })
        .finally(() => {
          state.loading = false;
        });
    }

    function skip () {
      router.push('/login/redirect');
      window.location.href = '/login/twofactor/skip';
    }

    function cancel () {
      if (dashboardRoute.value) {
        router.push('/login/redirect');
        window.location.href = '/login/redirect';
        return;
      }
      router.push('/login');
    }

    return {
      skipAllowed,
      skipAllowedCount,
      state,
      codeValidator,
      getTwoFactorActivateQr,
      confirm,
      skip,
      cancel,
      data,
      dashboardRoute
    };
  }
};
</script>

<style lang="scss" scoped>

.qr-box{
  display: flex;
  width: 100%;
  padding-top:20px;
  padding-bottom:15px;

  .qrcode {
    max-width: 160px;
    margin-right:25px;
  }

  a {
    display:block;
    margin-top:10px;
  }

}

.login-form {
  &::v-deep(.input-control) {
    width: 100%;
  }
}

.form-controls {
  display: flex;
  justify-content: space-between;
  margin-top:15px;

}

.links {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  font-size: 16px;
}

</style>
