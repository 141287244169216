<template>
  <div v-if="data.state.maglrLogin || data.state.providers.length === 0">
    <h3 class="loginheader">
      {{ $t('password-confirm-title') }}
    </h3>
    <p v-html="$t('password-confirm-description')" />
    <div class="login-form" @submit="passwordConfirm()">
      <form @submit.prevent>
        <MField
          label-variant="is-dark"
          :validator="passwordValidator"
          :label-left="$t('wachtwoord')"
          label-alignment="is-top"
          label-position="is-top-bottom"
        >
          <MInput
            v-model="state.password"
            name="password"
            type="password"
            :placeholder="$t('wachtwoord')"
            @keyup.enter="passwordConfirm"
          />
        </MField>
      </form>
    </div>
    <div class="form-controls">
      <MButton size="is-large" variant="is-accent-3" @click="back">
        {{ $t('cancel_button') }}
      </MButton>
      <MButton :loading="state.loading" size="is-large" variant="is-accent-2" @click="passwordConfirm">
        {{ $t('password-confirm') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { useData } from '../services/Data';
import { useApi } from '../services/Api';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { computed, reactive } from 'vue';
import MField from '../../../submodules/vue-framework/src/components/Container/Field.vue';
import MInput from '../../../submodules/vue-framework/src/components/Form/Input.vue';
import MButton from '../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'PasswordConfirm',
  components: { MButton, MInput, MField },
  setup: function () {
    const data = useData();
    const api = useApi();
    const router = useRouter();
    const i18n = useI18n();
    const state = reactive({
      password: '',
      errors: {},
      loading: false
    });

    function openUrl (url) {
      window.location.href = url;
    }

    const passwordValidator = computed(() => {
      if (state.errors.password) {
        return {
          message: state.errors.password,
          state: 'is-danger',
          validation: false
        };
      }

      return {
        message: '',
        state: 'is-default',
        validation: true
      };
    });

    async function checkLogin () {
      api.hasConfirmPassword()
        .then(() => {
          // eslint-disable-next-line vue/no-parsing-error
        }
        ).catch(error => {
          if (error.response.data.error === 'Unauthenticated.') {
            router.push('/login');
          }
        });
    }

    checkLogin();

    async function passwordConfirm () {
      state.loading = true;
      state.errors = {};
      data.state.password = state.password;
      api.confirmPassword({ password: state.password })
        .then(r => {
          data.state.password_confirmed = true;
          router.push(data.state.nextstep);
        })
        .catch(e => {
          if (e.response.data.message === '') {
            data.state.password_confirmed = true;
            router.push(data.state.nextstep);
          }
          state.errors.password = i18n.t('error invalid login');
        })
        .finally(() => {
          state.loading = false;
        });
    }

    function back () {
      router.push('/login/redirect');
      window.location.href = '/login/redirect';
    }

    return {
      state,
      passwordValidator,
      openUrl,
      passwordConfirm,
      back,
      data
    };
  }
};
</script>

<style lang="scss" scoped>

.form-controls {
  display: flex;
  justify-content: space-between;
  margin-top:15px;

}

</style>
