<template>
  <h3 class="loginheader">
    {{ $t('activate two factor authentication success title') }}
  </h3>
  <p>{{ $t('activate two factor authentication success description') }}</p>
  <a class="helplink" target="_blank" :href="$t('help-url')">{{ $t('help') }}</a>

  <div class="list-block center-box">
    <ul class="list center codes">
      <li v-for="code in state.codes" :key="code">
        {{ code }}
      </li>
    </ul>
  </div>

  <div v-for="error in state.errors" :key="error" class="error">
    {{ error }}
  </div>

  <div class="form-controls">
    <MButton size="is-large" variant="is-accent-2" to="/login/redirect" @click="toDashboard">
      {{ $t('goto_dashboard') }}
    </MButton>
  </div>
</template>

<script>
import { useApi } from '../../services/Api';
import { reactive } from 'vue';
import { useData } from '../../services/Data';
import { useRouter } from 'vue-router';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'ConfirmTwoFactor',
  components: { MButton },
  setup () {
    const data = useData();
    const api = useApi();
    const router = useRouter();
    const state = reactive({
      codes: '',
      errors: [],
      loading: false
    });

    if (data.state.firstpage === false) {
      data.state.firstpage = '/login/twofactor/confirm';
    }

    function getRecoveryCodes () {
      api.twoFactorRecoveryCodes().then(
        response => {
          state.codes = response.data;
        }
      ).catch(
        error => {
          state.errors.push(error.response.data.error);
        }
      );
    }
    getRecoveryCodes();

    function toDashboard () {
      router.push('/login/redirect');
      window.location.href = '/login/redirect';
    }

    return { state, toDashboard, getRecoveryCodes };
  }
};
</script>

<style lang="scss" scoped>

.list-block{
  margin-top: 30px;
  margin-bottom: 40px;
  background: #F5F6F7;
  box-sizing: border-box;
  padding:20px;
  font-size:14px
}

.center-box{
  display: flex;
  width: 100%;
}

.center{
  margin-left: auto;
  margin-right: auto;
}

.button-container {
    &::v-deep(.button) {
        width: 100%;
    }
}
.codes {
  line-height: 22px;
}

</style>
