<template>
  <div v-if="data.state.maglrLogin || data.state.providers.length === 0">
    <h3 class="loginheader">
      {{ $t('disabled two factor title') }}
    </h3>
    <p v-html="$t('disabled two factor description')" />

    <div class="form-controls">
      <MButton size="is-large" variant="is-accent-2" to="/login/redirect" @click="toDashboard">
        {{ $t('goto_dashboard') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { useData } from '../../services/Data';
import { reactive } from 'vue';
import { useApi } from '../../services/Api';
import { useRouter, useRoute } from 'vue-router';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'DeactivateTwoFactor',
  components: { MButton },
  setup () {
    const data = useData();
    const api = useApi();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      codes: '',
      errors: [],
      loading: false
    });

    function deactivateTwoFactor () {
      api.twoFactorAuthDeactivate().then(
        response => {
          state.codes = response.data;
        }
      ).catch(
        error => {
          if (error.response.data.error === 'Unauthenticated.') {
            data.state.redirectAfterLogin = '/login/twofactor/deactivate';
            router.push('/login');
          }
          if (error.response.data.message === 'Password confirmation required.') {
            data.state.nextstep = route.path;
            router.push('/login/confirm');
          }
          state.errors.push(error.response.data.error);
        }
      );
    }
    deactivateTwoFactor();

    function toDashboard () {
      router.push('/login/redirect');
      window.location.href = '/login/redirect';
    }

    return { state, toDashboard, data };
  }
};
</script>

<style lang="scss" scoped>

.button-container {
    &::v-deep(.button) {
        width: 100%;
    }

    margin-top:40px;
    display:block;
}

</style>
