import { inject, provide, reactive } from 'vue';

export const DataKey = Symbol('DataKey');

export function createData () {
  const state = reactive({
    maglrLogin: true,
    whitelabel: false,
    providers: [],
    email: '',
    user: {},
    password: false,
    login_success: false,
    password_confirmed: false,
    firstpage: false,
    redirectAfterLogin: false,
    nextstep: '/login/twofactor/activate',
    dashUri: '',
    twoFactor: false,
    twoFactorSkipAllowed: false,
    twoFactorSkipAllowedCount: 0,
    singleSignOnSkipAllowed: false,
    singleSignOnSkipAllowedCount: 0,
    singleSignOnProvider: '',
    hasSingleSignOn: false,
    needSingleSignOn: false,
    loadingUser: true
  });
  return { state };
}

export function provideData () {
  const inst = createData();
  provide(DataKey, inst);
  return inst;
}

export function useData () {
  const inst = inject(DataKey);

  if (!inst) throw new Error('Datajs use error');

  return inst;
}
