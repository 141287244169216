<template>
  <div class="client-warning">
    <h3 class="loginheader">
      {{ $t('two-factor-client-warning-title') }}
    </h3>
    <p>{{ $t('two-factor-client-warning-description') }}</p>

    <a target="_blank" class="helplink" :href="$t('help-url')">{{ $t('help') }}</a>

    <div class="form-controls">
      <MButton size="is-large" variant="is-accent-2" @click="next">
        {{ $t('two-factor-client-warning-connect') }}
      </MButton>

      <div class="links">
        <MLink v-if="skipAllowed" variant="is-darker" icon-right="long-arrow-right" @click="skip">
          {{ $t('skip') }} ({{ skipAllowedCount }}x)
        </MLink>
      </div>
    </div>
  </div>
</template>
<script>
import { useData } from '../../services/Data';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';
import MLink from '../../../../submodules/vue-framework/src/components/Element/Link.vue';

export default {
  name: 'NeedTwoFactor',
  components: { MLink, MButton },
  setup () {
    const data = useData();
    const router = useRouter();

    const skipAllowed = computed(() => {
      return data.state.twoFactorSkipAllowed;
    });

    const skipAllowedCount = computed(() => {
      return data.state.twoFactorSkipAllowedCount;
    });

    function skip () {
      router.push('/login/redirect');
      window.location.href = '/login/twofactor/skip';
    }

    function next () {
      router.push('/login/twofactor/activate');
    }

    function cancel () {
      router.push('/login');
    }

    return {
      skipAllowed,
      skipAllowedCount,
      next,
      skip,
      cancel,
      data
    };
  }
};
</script>

<style lang="scss" scoped>

.qr-box{
  display: flex;
  width: 100%;
  padding-top:20px;
  padding-bottom:15px;

  .qrcode {
    max-width: 160px;
    margin-right:25px;
  }

  a {
    display:block;
    margin-top:10px;
  }

}

.login-form {
  &::v-deep(.input-control) {
    width: 100%;
  }
}

.form-controls {
  display: flex;
  justify-content: space-between;
  margin-top:15px;

}

.links {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  font-size: 16px;
}

</style>
