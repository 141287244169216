import { createRouter, createWebHistory } from 'vue-router';
import LoginContainer from '../components/LoginContainer.vue';
import RequestPassword from '../components/password/RequestPassword.vue';
import ResetPassword from '../components/password/ResetPassword.vue';
import PasswordConfirm from '../components/PasswordConfirm.vue';
import RedirectPage from '../components/RedirectPage.vue';
import DeactivateSingleSingOn from '../components/singesignon/DeactivateSingleSignOn.vue';
import NeedTwoFactor from '../components/twofactor/NeedTwoFactor.vue';
import ActivateSingleSignOn from '../components/singesignon/ActivateSingleSignOn.vue';
import LoginSingleSignOn from '../components/singesignon/LoginSingleSignOn.vue';
import FailSingleSingOn from '../components/singesignon/FailSingleSignOn.vue';
import SuccessSingleSingOn from '../components/singesignon/SuccessSingleSignOn.vue';
import ActivateTwoFactor from '../components/twofactor/ActivateTwoFactor.vue';
import ConfirmTwoFactor from '../components/twofactor/ConfirmTwoFactor.vue';
import ValidateTwoFactor from '../components/twofactor/ValidateTwoFactor.vue';
import RecoveryCodeUsed from '../components/twofactor/RecoveryCodeUsed.vue';
import DeactivateTwoFactor from '../components/twofactor/DeactivateTwoFactor.vue';
import ConfirmEmail from '../components/email/ConfirmEmail.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginContainer
  },
  {
    path: '/login/requestpassword',
    name: 'RequestPassword',
    component: RequestPassword
  },
  {
    path: '/user/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/login/confirm',
    name: 'PasswordConfirm',
    component: PasswordConfirm
  },
  {
    path: '/login/redirect',
    name: 'Redirect',
    component: RedirectPage
  },
  {
    path: '/login/twofactor',
    name: 'NeedTwoFactor',
    component: NeedTwoFactor
  },
  {
    path: '/login/twofactor/activate',
    name: 'ActivateTwoFactor',
    component: ActivateTwoFactor
  },
  {
    path: '/user/twofactor/activate',
    name: 'UserActivateTwoFactor',
    component: ActivateTwoFactor
  },
  {
    path: '/user/twofactor/deactivate',
    name: 'DeactivateTwoFactor',
    component: DeactivateTwoFactor
  },
  {
    path: '/login/twofactor/confirm',
    name: 'ConfirmTwoFactor',
    component: ConfirmTwoFactor
  },
  {
    path: '/login/twofactor/validate',
    name: 'ValidateTwoFactor',
    component: ValidateTwoFactor
  },
  {
    path: '/login/twofactor/recovery',
    name: 'RecoveryCodeUsed',
    component: RecoveryCodeUsed
  },
  {
    path: '/login/sso/activate',
    name: 'ActivateSingleSignOn',
    component: ActivateSingleSignOn
  },
  {
    path: '/user/sso/activate',
    name: 'UserActivateSingleSignOn',
    component: ActivateSingleSignOn
  },
  {
    path: '/login/sso',
    name: 'LoginSingleSignOn',
    component: LoginSingleSignOn
  },
  {
    path: '/login/sso/fail',
    name: 'FailSingleSingOn',
    component: FailSingleSingOn
  },
  {
    path: '/login/sso/fail/email',
    name: 'FailSingleSingOnMail',
    component: FailSingleSingOn
  },
  {
    path: '/login/sso/success',
    name: 'SuccessSingleSingOn',
    component: SuccessSingleSingOn
  },
  {
    path: '/user/sso/fail',
    name: 'FailUserSingleSingOn',
    component: FailSingleSingOn
  },
  {
    path: '/user/sso/fail/email',
    name: 'FailUserSingleSingOnMail',
    component: FailSingleSingOn
  },
  {
    path: '/user/sso/success',
    name: 'SuccessUserSingleSingOn',
    component: SuccessSingleSingOn
  },
  {
    path: '/user/sso/deactivate',
    name: 'DeactivateSingleSingOn',
    component: DeactivateSingleSingOn
  },
  {
    path: '/user/email/confirm',
    name: 'ConfirmEmail',
    component: ConfirmEmail
  }
];

const index = createRouter({
  history: createWebHistory('/'),
  routes
});

export default index;
