<template>
  <div>
    <div class="intro">
      <h3 class="loginheader">
        {{ title }}
      </h3>
      <p>{{ description }}</p>
    </div>

    <div class="single-sign-on-linked" />

    <MButton size="is-large" variant="is-accent-2" @click="redirect">
      {{ $t('success_single_sign_on_button') }}
    </MButton>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useData } from '../../services/Data';
import MButton from '../../../../submodules/vue-framework/src/components/Element/Button.vue';

export default {
  name: 'SuccessSingleSignOn',
  components: { MButton },
  setup: function () {
    const router = useRouter();
    const i18n = useI18n();
    const data = useData();
    const title = computed(() => {
      return i18n.t('success_single_sign_on_title').replaceAll('[[provider]]', data.state.singleSignOnProvider);
    });
    const description = computed(() => {
      return i18n.t('success_single_sign_on_description').replaceAll('[[provider]]', data.state.singleSignOnProvider);
    });
    function redirect () {
      router.push('/login/redirect');
      window.location.href = '/login/redirect';
    }
    return {
      redirect,
      title,
      description
    };
  }
};
</script>

<style lang="scss" scoped>

.intro{
  padding-bottom: 35px;
}

.button-container {
    &::v-deep(.button) {
        width: 100%;
    }
}

.single-sign-on-linked {
  background-repeat: no-repeat;
  background-size: contain;
  width: 374px;
  height: 129px;
  margin-bottom: 30px;
  background-image: url("../../../img/single_sign_linked.svg");
}

</style>
